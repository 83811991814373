<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="my-5">システム追加項目の設定</h1>
        <banner-hint>
          システム追加項目では追加でHPに記載しておきたい情報を内容問わず自由に登録でき、内容はシステムページ最下部に表示されます。<br />
          良くある使われ方として禁止事項・盗撮注意等の情報を表示するために使われます。
        </banner-hint>
        <icon-info icon="lightbulb-on-outline" class="mt-7" :square="true">
          項目が複数ある場合は登録した順に上から下にホームページに表示されます。
        </icon-info>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-for="(info, index) in additionalInfoRows"
        :key="info.info_id"
      >
        <v-card
          class="px-10 py-5"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12">

              <!-- 入力一列 -->
              <v-form :ref="'form-info-' + info.info_id">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="info.info_title"
                      required
                      counter="30"
                      label="タイトル（項目名）"
                      :rules="[valiRules.required, valiRules.max30]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="info.description"
                      filled
                      auto-grow
                      counter="1000"
                      label="内容"
                      :rules="[valiRules.required, valiRules.max1000]"
                    ></v-textarea>

                    <!-- 定型文セレクター -->
                    <!-- <select-boilerplate
                      class="mt-2"
                      :apiAdmin="apiAdmin"
                      :shopData="shopData"
                      :inputField.sync="info.description"
                      @reset="$emit('reset')"
                    ></select-boilerplate> -->
                  </v-col>
                </v-row>
                <v-row>
                  <!-- 画像添付 -->
                  <v-col cols="10">
                    <v-file-input
                      v-model="attachments['file-' + info.info_id]"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="先頭に表示される画像を添付（必要であれば）"
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="info.image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="80"
                      contain
                      :src="info.image_url"
                    >
                    </v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="d-flex justify-end align-center pt-0"
                  >
                    <v-btn
                      v-if="info.create"
                      depressed small
                      color="accent"
                      @click="createRow(info)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(info)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
        depressed
        color="primary"
        @click="addBlankRow()"
        >新たに項目を追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!additionalInfoRows.length" flat>
          <v-card-text>追加項目が登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import IconInfo from '@/components/_IconInfo.vue'
import BannerHint from '@/components/_BannerHint.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      additionalInfoRows: [],
      attachments: {},
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getAdditionalInfo(),
      ])

      this.loading = false
    },

    //入力列追加
    addBlankRow() {
      if (this.additionalInfoRows.length >= 5) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.additionalInfoRows.push({
        info_id: ++this.maxId,
        info_title: '',
        image_url: null,
        description: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getAdditionalInfo() {
      return this.publicApi.getReqSitePublic('system-additional-info/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxId = Math.max(...results.map( row => parseInt(row.info_id) ))

        this.additionalInfoRows = results
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(info) {
      if (!this.$refs['form-info-' + info.info_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData()
      formData.append('info_title', info.info_title)
      formData.append('description', info.description)
      if (Object.keys(this.attachments).length) formData.append('image_url', this.attachments['file-' + info.info_id])

      this.adminApi.apiReqWithData('POST', 'create/system-additional-info/', formData).then( response => {
        info.info_id = response.info_id
        info.create = false
        delete this.attachments['file-' + info.info_id]

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(info) {
      if (!this.$refs['form-info-' + info.info_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'update/system-additional-info/' + info.info_id
      const formData = new FormData()
      formData.append('info_title', info.info_title)
      formData.append('image_url', this.attachments['file-' + info.info_id])
      formData.append('description', info.description)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          delete this.attachments['file-' + info.info_id]
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.additionalInfoRows[index].create) {
        this.additionalInfoRows.splice(index, 1)
        return
      }

      const apiPath = 'delete/system-additional-info/' + this.additionalInfoRows[index].info_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.additionalInfoRows.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
